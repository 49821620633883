import { AfterViewInit, Component, HostListener, Inject, NgZone, OnDestroy, PLATFORM_ID, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieUtil } from '@utils/cookies';

import { UtilsMethods_Services } from '@utils/utils';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@enviroment/environment';
import { getDataCampaign } from '@utils/get-campaign';
import { Subscription} from 'rxjs';
import { ValidateTokenService } from '@apiShared/user/token/validate-token.service';
import { SignOut_Util } from '@utils/sign-out';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertConfirmComponent } from './resources-alerts/alert-confirm/alert-confirm.component';
import { CloseModal } from '@class/close-modal/close-modal';
import { Globals } from '@config/global';
import { NotificacionService } from '@utils/notificacion';
import { ModalDownloadAppFullscreenComponent } from "./modals/modal-download-app-fullscreen/modal-download-app-fullscreen.component";

declare let gtag: Function;

declare global {
  var HubSpotConversations: {
    widget: {
      remove: () => void;
      load: () => void;
      open: () => void;
    };
  };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements AfterViewInit, OnDestroy{

  private tokenExpirationSubscription!: Subscription;
  env = environment;
  title = 'MejorCDT';
  retryAttempts = 2; // Número máximo de intentos para checkQueryParam
  ico16!: any;
  ico32!: any;
  ico48!: any;
  ico64!: any;
  ico128!: any;
  mouseInactive: number = 0;
  flagMouseActive: boolean = false;
  flagModalDownloadApp: boolean = false;
  timeMouseActive: number = 35000;
  observer: MutationObserver | undefined;
  pageNotAllowedBonusGift: any = [
    '/preguntas/coltefinanciera/flamingo',
    '/preguntas-ban100',
    '/preguntas-bancamia',
    '/preguntas-bancow',
    '/preguntas-bancomundomujer',
    '/preguntas-mibanco',
    '/preguntas-coltefinanciera',
    '/preguntas-credifamilia',
    '/preguntas-financieradannregional',
    '/datos-personales',
    '/datos-financieros',
    '/sube-tus-documentos',
    '/asegura-tu-tasa',
    '/transfiere-el-dinero',
    '/cdt-completado',
    '/ops/dashboard',
    '/portal/login',
    '/portal/dashboard',
    '/portal/referrer',
    '/login',
    '/verificando-firma',
    '/mis-procesos',
    '/continuar-proceso',
    '/no-podemos-continuar-con-tu-proceso',
    '/firma-los-documentos-en-fisico',
    '/trabajo',
    '/validar-identidad',
    "/hoy-los-bancos-ofrecen",
    "/cdt-simulador?promo=gift-voucher",
    "/golf18",
    "/a-unos-minutos-de-abrir-tu-cdt",
    "/cdt-simulador",
    "/orden-inversion",
    "/cancelacion",
    "/4B6XN8K2H7VY3W9M5Q1Z9A3JN7M5D1H8S4L0F2K",
    "/dias-azules",
    "/sueldo-inteligente",
    "/referidos",
    "/referidos/terminos",
    "/cdt-nicolasabril",
    "/cdt-financiatuvida",
    "/cdt-claudiauribe",
    "/confirma-tus-datos",
    '/preguntas-credifamilia',
    '/v2/preguntas/coltefinanciera/flamingo?campaign=Flamingo',
    '/v2/preguntas-ban100',
    '/v2/preguntas-bancamia',
    '/v2/preguntas-bancow',
    '/v2/preguntas-bancomundomujer',
    '/v2/preguntas-mibanco',
    '/v2/preguntas-credifamilia',
    '/v2/preguntas-coltefinanciera',
    '/v2/preguntas-financieradannregional',
    '/v2/preguntas-cdturbo',
    '/v2/datos-personales',
    '/v2/datos-financieros',
  ];

  @ViewChild('appContainer', { read: ViewContainerRef }) container!: ViewContainerRef;

  constructor(
    private ngZone: NgZone,
    private cookies : CookieUtil,
    private utils : UtilsMethods_Services,
    private signOut: SignOut_Util,
    private closeModal: CloseModal,
    private notification: NotificacionService,
    private _getDataCampaign: getDataCampaign,
    private modal: NgbModal,
    private validateToken: ValidateTokenService,
    private router: Router,
    private renderer: Renderer2,
    private globals_var: Globals,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)){
      this.ico16 = document.querySelector("#ico16");
      this.ico32 = document.querySelector("#ico32");
      this.ico48 = document.querySelector("#ico48");
      this.ico64 = document.querySelector("#ico64");
      this.ico128 = document.querySelector("#ico128");
      this.loadHammerJs();
      this.addClassPathInView();
      this.campaignVIP();
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)){

      const check_id_token_user = this.cookies.check("id_token_user");

      if(check_id_token_user){
        this.startTokenExpirationCheck()
      }

      this.backToStep();
      this.RefInfluencer('ref');
      setTimeout(() => {
        this.LoadChatFaviconBrand();
      }, 2000);
    }
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Espera 5 segundos antes de iniciar la observación nuevamente
        this.ngZone.run(() => {
          setTimeout(() => {
            this.retryAttempts = 0;
            this.LoadChatFaviconBrand();
          }, 100);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.stopObserving();
  }



  stopObserving() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  loadHammerJs() {
    import('hammerjs');
  }

  activateCampaign(){
    this._getDataCampaign.activateCampaignData().subscribe();
  }

  addClassPathInView() {
    this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd) {
        this.activateCampaign();
        setTimeout(() => {
          this.retryAttempts = 5;
          this.LoadChatFaviconBrand();
        }, 2000);


        const ID_APLICATION_TUBO_MCDT:any = document.getElementById('aplication-tubo-mcdt');
        if (ID_APLICATION_TUBO_MCDT) {

          while (ID_APLICATION_TUBO_MCDT.classList.length > 0) {
            ID_APLICATION_TUBO_MCDT.classList.remove(ID_APLICATION_TUBO_MCDT.classList.item(0));
          }

          const path = window.location.pathname
          .replaceAll('/', '_');

          if (path) {
            this.renderer.addClass(ID_APLICATION_TUBO_MCDT, path);
          }
        }
      }
    });
  }


  campaignVIP(){
    this.notification.receiveNotificacion.subscribe((data: any) => {

      let api = data.api;
      switch (api) {

        case "api_notification_activate_VIP":
          const ID_PROJECT_MCDT_ELEM = document.getElementById('project-mcdt');
          if (ID_PROJECT_MCDT_ELEM) {
            this.renderer.addClass(ID_PROJECT_MCDT_ELEM, 'vip-activate');
          }
          break;

        default:
          break;

      }

    })
  }

  @HostListener('document:mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent) {
    if (isPlatformBrowser(this.platformId)){
      const locationPath = window.location.pathname;
      const is_phone_full = this.cookies.check('phone-full');
      const is_phone_number = this.cookies.check('phone-number');
      const is_token_user = this.cookies.check('id_token_user');
      if(!this.pageNotAllowedBonusGift.includes(`${locationPath}`) && !is_phone_full && !is_phone_number  && !is_token_user){
        if(!this.flagMouseActive){
          setTimeout(() => {
            this.flagMouseActive = true;
          }, this.timeMouseActive);
        } else {
          this.mouseInactive++;
          if(!this.cookies.check("open_modal_gift")){
            this.cookies.save("open_modal_gift", "false", 360);
            this.openAlertConfirmation({
              title: `
              <div class="text-center">
                <img src="${this.env.S3_STATIC_FILES}/images/web/ilustrations/banner-gift.png"> </img>
                ¡Tenemos un regalo especial para ti! <br/>
              </div>

              `,
              message: `
              <span class='description-gift'>
                Abre tu CDT ${this.generateDynamicMessage()} <br/> <br/>
                Este bono regalo se sumará exclusivamente <br/> al valor de tu inversión
              </span>
              `,
              no_padding_header: true,
              textButtons: {
                confirm: {
                  text: "Quiero reclamar mi bono regalo",
                  id: 'select_claim_bonus50k'
                },
                cancel: {
                  text: "No me interesa",
                  id: 'select_reject_bonus50k '
                }
              }
            }, () => { this.acceptedAsGift() });
          }
        }
      }
    }
  }

  /*
  * Genera un mensaje dinámico dependiendo del día de la semana.
  * Si hoy es jueves antes de las 3:00 PM, devuelve un mensaje específico.
  * Si hoy es viernes, devuelve un mensaje para el próximo viernes.
  * En cualquier otro día, devuelve un mensaje para el próximo viernes después del día actual.
  * @returns El mensaje dinámico generado.
 */
  generateDynamicMessage(): string {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const textContinue = 'y recibe gratis un bono regalo de <b>$50.000</b>'
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    // Verificar si hoy es jueves y si es antes de las 3:00 PM
    if (dayOfWeek === 5) {
      const now = new Date();
      const cutoffTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 0, 0); // 3:00 PM

      if (now < cutoffTime) {
        return "<b>hoy antes de las 3:00pm</b> para que recibas gratis un bono regalo de <b>$50.000</b> ";
      }
    }
    // Si hoy es viernes, mostrar el mensaje para el próximo viernes
    else if (dayOfWeek === 5) {
      const nextFriday = new Date(today);
      nextFriday.setDate(today.getDate() + 7); // Obtener el próximo viernes

      const day = nextFriday.getDate();
      const monthIndex = nextFriday.getMonth();
      const month = months[monthIndex].charAt(0).toUpperCase() + months[monthIndex].slice(1);
      const year = nextFriday.getFullYear();

      return `<b>antes del próximo Viernes ${day} de ${month} de ${year}</b> a las 3:00pm,  ${textContinue}`;
    }

    // Si no es jueves ni viernes, mostrar el mensaje para el próximo viernes después del día actual
    const nextFriday = new Date(today);
    nextFriday.setDate(today.getDate() + ((5 - dayOfWeek + 7) % 7)); // Obtener el próximo viernes

    const day = nextFriday.getDate();
    const monthIndex = nextFriday.getMonth();
    const month = months[monthIndex].charAt(0).toUpperCase() + months[monthIndex].slice(1);
    const year = nextFriday.getFullYear();

    return `<b>antes del próximo Viernes ${day} de ${month} de ${year}</b> a las 3:00pm, ${textContinue}`;
  }

  acceptedAsGift(){
    this.cookies.save('activate_promo', 'true', 1)
    this.router.navigate(['/cdt-simulador'], { queryParams: { promo: 'gift-voucher'} } )
  }

  openAlertConfirmation(modalData: {title: string, message: string, textButtons: {}, no_padding_header: boolean}, execute: () => void) {
    if(this.mouseInactive <= 1){
      const modalRef = this.modal.open(AlertConfirmComponent,
        {
          centered: true,
          backdrop: 'static',
          size: 'modal-alert-bono-regalo-50k-zoom'
        }
      );
      modalRef.componentInstance.modalData = modalData;
      modalRef.componentInstance.getResult().subscribe((result: boolean) => {
        if (result) {
          execute();
        }
      });
    }
  }

  startTokenExpirationCheck(): void {
    this.tokenExpirationSubscription = this.validateToken.getTokenExpirationStatus()
      .subscribe((isExpired: boolean) => {
        if (isExpired) {
          if(this.cookies.get('id_token_user')){
            this.signOut.SignOut('');
            this.stopTokenExpirationCheck();
            this.closeModal._CloseModal('btn-close-modal-loading');
            this.router.navigate(['/login']);
          }
        }
      });
  }

  stopTokenExpirationCheck(): void {
    if (this.tokenExpirationSubscription) {
      this.tokenExpirationSubscription.unsubscribe();
    }
  }

  //Desde la función redirect, detectaremos si viene el queryparam de campaign, y si si, añadimos en el head el script de Flamingo,
  LoadChatFaviconBrand() {

    if (isPlatformBrowser(this.platformId)) {

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const campaignParam = params.get('campaign');
      const locationPath = window.location.pathname;
      const is_active_campaign_month = this.utils.isCampaignMonthReturnBool();
      const detect_screen_mode = this.utils.detectScreenMode();

      const views_no_loads_chaths_is_active_campaign_month = [
        '/',
        '/cdt-cupido',
        '/dias-azules'
      ];

      const no_load_chaths_onpage = [
        '/v2/preguntas/coltefinanciera/flamingo?campaign=Flamingo',
        '/v2/preguntas-ban100',
        '/v2/preguntas-bancamia',
        '/v2/preguntas-bancow',
        '/v2/preguntas-bancomundomujer',
        '/v2/preguntas-mibanco',
        '/v2/preguntas-credifamilia',
        '/v2/preguntas-coltefinanciera',
        '/v2/preguntas-financieradannregional',
        '/v2/preguntas-cdturbo',
        '/v2/datos-personales',
        '/v2/datos-financieros',
        '/confirma-tus-datos',
        '/cdt-claudiauribe'
      ];

      if (
        !no_load_chaths_onpage.includes(locationPath) &&
        (
          detect_screen_mode ||
          (
            !views_no_loads_chaths_is_active_campaign_month.includes(locationPath) ||
            (views_no_loads_chaths_is_active_campaign_month.includes(locationPath) && !is_active_campaign_month && !detect_screen_mode)
          )
        )
      ) {

        if (campaignParam && campaignParam.toLowerCase() === this.globals_var.FLAMINGO.toLowerCase()) {
          this.addScriptToHead('//js.hs-scripts.com/23231946.js');
          this.ico16.href = "https://d196s6wuwr5feg.cloudfront.net/assets/favicons/flamingo.png";
          this.ico32.href = "https://d196s6wuwr5feg.cloudfront.net/assets/favicons/flamingo.png";
          this.ico48.href = "https://d196s6wuwr5feg.cloudfront.net/assets/favicons/flamingo.png";
          this.ico64.href = "https://d196s6wuwr5feg.cloudfront.net/assets/favicons/flamingo.png";
          this.ico128.href = "https://d196s6wuwr5feg.cloudfront.net/assets/favicons/flamingo.png";
          this.retryAttempts = 0;
        } else if (this.retryAttempts > 0) {
          this.retryAttempts--;
          setTimeout(() => {
            this.LoadChatFaviconBrand();
          }, 1000);
        } else {
          this.ico16.href = "https://d196s6wuwr5feg.cloudfront.net/assets/images/web/favicons/icon16.png";
          this.ico32.href = "https://d196s6wuwr5feg.cloudfront.net/assets/images/web/favicons/icon32.png";
          this.ico48.href = "https://d196s6wuwr5feg.cloudfront.net/assets/images/web/favicons/icon48.png";
          this.ico64.href = "https://d196s6wuwr5feg.cloudfront.net/assets/images/web/favicons/icon64.png";
          this.ico128.href = "https://d196s6wuwr5feg.cloudfront.net/assets/images/web/favicons/icon128.png";
          this.addScriptToHead('//js.hs-scripts.com/23231946.js');
        }
      } else {

        if (window.HubSpotConversations && window.HubSpotConversations.widget) {
          window.HubSpotConversations.widget.remove();
        }
      }
    }

  }


  /* Si existe el paremetro tube, significa que el usuario accedio por el link y lo redireccionaremos al paso en que está  */
  backToStep(){
    const isIdProcess = !!this.utils.getQueryParam('process_id');
    const isIdTicket = !!this.utils.getQueryParam('ticket');
    const isPathHome = window.location.pathname === '/';
    const query_url =  {
      process_id: this.utils.getQueryParam('process_id'),
      ticket: this.utils.getQueryParam('ticket'),
      campaign: this.utils.getQueryParam('campaign')
    };
    if ((isIdProcess || isIdTicket) && isPathHome) {
      this.router.navigate(['/continuar-proceso'], { queryParams: query_url })
    }
  }

  /* Identificamos de que referido viene */
  RefInfluencer(query: any){
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var resp = urlParams.get(query);

    if(resp != null){
      this.cookies.save('ref', resp, 1);
    }
  }

  // Función para agregar el script de HS al ID_PROJECT_MCDT_ELEMo <head> del documento
  addScriptToHead(scriptUrl: string) {
    if (isPlatformBrowser(this.platformId)){
      // Crear un ID_PROJECT_MCDT_ELEMo <script> usando el renderer
      const scriptHS = this.renderer.createElement('script');
      scriptHS.type = 'text/javascript';
      scriptHS.src = scriptUrl;

      // Agregar el ID_PROJECT_MCDT_ELEMo <script> al ID_PROJECT_MCDT_ELEMo <head> del documento
      this.renderer.appendChild(document.head, scriptHS);

      // Configurar un evento onload para el script
      scriptHS.onload = () => {
        // Esperar a que HubSpotConversations esté disponible antes de acceder a la propiedad widget
        this.waitForHubSpotConversations().then(() => {
          // Verificar si HubSpotConversations y su propiedad widget existen
          if (window.HubSpotConversations && window.HubSpotConversations.widget) {
            // Acceder a la propiedad widget y ejecutar métodos
            window.HubSpotConversations.widget.remove(); // Remover el widget actual
            window.HubSpotConversations.widget.load();
          }
        });
      };
    }
  }

  // Función para esperar a que HubSpotConversations esté disponible
  waitForHubSpotConversations(): Promise<void> {
    return new Promise((resolve) => {
      const checkHubSpotConversations = () => {
        // Verificar si HubSpotConversations está disponible en la ventana global
        if (window.HubSpotConversations) {
          resolve(); // Resuelve la promesa cuando HubSpotConversations está disponible
        } else {
          setTimeout(checkHubSpotConversations, 100); // Revisar cada 100 ms hasta que esté disponible
        }
      };
      // Iniciar el proceso de espera
      checkHubSpotConversations();
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      const locationPath = window.location.pathname;
      if (this.globals_var.PATHS_SHOW_DOWNLOAD_APP_FULLSCREEN.includes(`${locationPath}`) || locationPath.startsWith('/mejores-cdt-')) {
        const threshold = 600; // Distancia desde el fondo para activar la alerta
        const position  = window.innerHeight + window.scrollY;
        const height    = document.body.offsetHeight;

        if (position >= height - threshold && !this.flagModalDownloadApp) {
          setTimeout(() => {
            this.container.createComponent(ModalDownloadAppFullscreenComponent);
          }, 100);
          this.flagModalDownloadApp = true;
        }
      }
    }
  }


}
